<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getCrewingPlusConnectWithSailor"
  :isLoading="isLoading"
  @saveReport="getCrewingPlusConnectWithSailorExcel"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'manager',
          text: this.$t('tableHeaders.manager'),
          sortable: false },
        { value: 'manager_full_name',
          text: this.$t('tableHeaders.manager_full_name'),
          sortable: false },
        { value: 'sailor_key',
          text: this.$t('tableHeaders.sailor_id'),
          sortable: false },
        { value: 'sailor_full_name',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'created_at',
          text: this.$t('tableHeaders.created_at'),
          sortable: false },
        { value: 'crewing_name',
          text: this.$t('tableHeaders.crewingName'),
          sortable: false },
        { value: 'date_end_proxy',
          text: this.$t('tableHeaders.date_end_proxy'),
          sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.crewingPlusConnectWithSailor,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getCrewingPlusConnectWithSailor', 'getCrewingPlusConnectWithSailorExcel'])
  }
}
</script>
